//https://www.digitalocean.com/community/tutorials/js-axios-vanilla-js

import 'regenerator-runtime/runtime'; // runtime for transpiled js, I think
import axios from 'axios';  // somehow I think parcel-bundler makes this line work -- see package.json
import * as THREE from 'three';

//const BASE_URL = 'https://bw5o4rwxkl.execute-api.us-west-2.amazonaws.com';

const getIpixJson = async (url) => {
  try {
    const response = await axios.get(url);    
    //const response = await axios.get(`${BASE_URL}/getIpixJson/333333/colorsquares-26-May-2015.json`);
    const ipixJson = response.data.ipixJson;
    //console.log(`GET: Here's the ipixJson`, ipixJson);
    return ipixJson;
  } catch (errors) {
    console.error(errors);
  }
};

function roundPlaces(i, decimalPlaces=2) {
  let scale = Math.pow(10,decimalPlaces);
  return (Math.round(i * scale) / scale).toFixed(decimalPlaces);
}


function debugInterval({func=null, funcArgs=[], microseconds=5000}) {
  if (func === null || typeof func !== 'function') {
    console.log('debugInterval2: ERROR: func must be a \'function\'');
    return;
  }
  if (funcArgs && !Array.isArray(funcArgs)) {
    console.log('debugInterval2: ERROR: funcArgs must be an array');
    return;
  }
  var interval = setInterval(func, microseconds, ...funcArgs);
}

function printObject3DMatrix(obj3D, name='THREE.Object3D') {
  if (typeof obj3D !== 'object') {
    console.log('printObject3DMatrix: ERROR: obj3D must be an \'object\'');
    return;
  }
  if (!('matrix' in obj3D)) {
    console.log('printObject3DMatrix: ERROR: obj3D must be a THREE.Object3D, and have a \'matrix\' key');
    return;
  }
  var objX = new THREE.Vector3();
  var objY = new THREE.Vector3();
  var objZ = new THREE.Vector3();
  obj3D.matrix.extractBasis(objX, objY, objZ);
    
  let objXx = roundPlaces(objX.getComponent(0));
  let objXy = roundPlaces(objX.getComponent(1));
  let objXz = roundPlaces(objX.getComponent(2));
  
  let objYx = roundPlaces(objY.getComponent(0));
  let objYy = roundPlaces(objY.getComponent(1));
  let objYz = roundPlaces(objY.getComponent(2));
  
  let objZx = roundPlaces(objZ.getComponent(0));
  let objZy = roundPlaces(objZ.getComponent(1));
  let objZz = roundPlaces(objZ.getComponent(2));
  console.log(`
${name} X: ${objXx} ${objXy} ${objXz}
${name} Y: ${objYx} ${objYy} ${objYz}
${name} Z: ${objZx} ${objZy} ${objZz}
`);
}

function printObject3D(obj3D, name='THREE.Object3D') {
  if (obj3D === null) {
    console.log('printObject3D: ERROR: obj3D must be a THREE.Object3D');
    return;
  }
  console.log(`${name}: type: ${obj3D.type}`);
  console.log(`${name}: geometry type: ${obj3D.geometry.type}`);
  let str = JSON.stringify(obj3D, null, 2);
  console.log(`${name}: ${str}`);
}

function printVector3(vec3, name='THREE.Vector3') {
  console.log(`
${name}:e up: ${roundPlaces(vec3.getComponent(0))} ${roundPlaces(vec3.getComponent(1))} ${roundPlaces(vec3.getComponent(2))}
`);
}

function initScene() {
  const scene = new THREE.Scene();
  return scene;
}
function initCamera(zposition) {
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000);
  //camera.position.z = 0.1;
  //camera.position.z = -250;
  camera.position.z = zposition
  return camera;
}
function initRenderer() {
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);
  return renderer;
}

function initControls(scene, camera, renderer, cube) {
  var targetRotationX = 0;
  var targetRotationOnMouseDownX = 0;

  var targetRotationY = 0;
  var targetRotationOnMouseDownY = 0;

  var mouseX = 0;
  var mouseXOnMouseDown = 0;

  var mouseY = 0;
  var mouseYOnMouseDown = 0;

  var windowHalfX = window.innerWidth / 2;
  var windowHalfY = window.innerHeight / 2;

  var finalRotationY;

  var halfPI = Math.PI/2;                                                                                                                       

  //debugInterval({func:printObject3DMatrix, funcArgs:[cube, 'cube']});
  
  function onDocumentMouseDown( event ) {
    event.preventDefault();
    document.addEventListener( 'mousemove', onDocumentMouseMove, false );
    document.addEventListener( 'mouseup', onDocumentMouseUp, false );
    document.addEventListener( 'mouseout', onDocumentMouseOut, false );
    mouseXOnMouseDown = event.clientX - windowHalfX;
    targetRotationOnMouseDownX = targetRotationX;
    mouseYOnMouseDown = event.clientY - windowHalfY;
    targetRotationOnMouseDownY = targetRotationY;
  }

  function onDocumentMouseMove( event ) {
    mouseX = event.clientX - windowHalfX;
    mouseY = event.clientY - windowHalfY;
    targetRotationX = targetRotationOnMouseDownX + (mouseX - mouseXOnMouseDown) * 0.02;
    targetRotationY = targetRotationOnMouseDownY + (mouseY - mouseYOnMouseDown) * 0.02;
  }

  function onDocumentMouseUp( event ) {
    document.removeEventListener( 'mousemove', onDocumentMouseMove, false );
    document.removeEventListener( 'mouseup', onDocumentMouseUp, false );
    document.removeEventListener( 'mouseout', onDocumentMouseOut, false );
  }

  function onDocumentMouseOut( event ) {
    document.removeEventListener( 'mousemove', onDocumentMouseMove, false );
    document.removeEventListener( 'mouseup', onDocumentMouseUp, false );
    document.removeEventListener( 'mouseout', onDocumentMouseOut, false );
  }

  document.addEventListener( 'mousedown', onDocumentMouseDown, false );
  window.addEventListener(
    'resize',
    () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      render();
    },
    false
  );
  function render() {
    var spinDecay = 0.05;

    // Horizontal rotation
    cube.rotation.y += ( targetRotationX - cube.rotation.y ) * spinDecay;

    // Vertical rotation -- keep these *two* lines of code -- watch out for
    // cube.rotation.x in being in both first and second lines with +=
    // or something!
    // THIS DOES NOT WORK!!
    //cube.rotation.x += ((targetRotationY - cube.rotation.x) * spinDecay);
    finalRotationY = (targetRotationY - cube.rotation.x) * spinDecay;
    cube.rotation.x += finalRotationY;

    // Place limits on vertical rotation
    if (cube.rotation.x <= halfPI && cube.rotation.x >= -halfPI) {
      cube.rotation.x += finalRotationY * spinDecay;
    }                                                                                                                                          
    if (cube.rotation.x > halfPI) {
      cube.rotation.x = halfPI;
    }                                                                                                                                          
    else if (cube.rotation.x < -halfPI ) {
      cube.rotation.x = -halfPI;
    }

    renderer.render( scene, camera );
  }
  function animate() {
    requestAnimationFrame(animate);
    render();
  }
  animate();
}

function createTextureFromBase64(b64Image) {
  // Create an image
  const image = new Image(); // or document.createElement('img' );
  // Create texture
  let texture = new THREE.Texture(image);
  // On image load, update texture
  image.onload = () =>  { texture.needsUpdate = true };
  // Set image source
  image.src = b64Image;
  //texture.flipY = false;
  return texture;
}

function createMaterialFromTexture(texture) {
  texture
  let material = new THREE.MeshBasicMaterial({map: texture});
  material.side = THREE.DoubleSide;
  return material;
}

function initPictoCubeFromBase64Arr(b64ImageArr) {
  // Create shape.  Ordering from Interactive Studio is: 3,2,4,5,0,1
  const materials = [
    createMaterialFromTexture(createTextureFromBase64(b64ImageArr[3])),
    createMaterialFromTexture(createTextureFromBase64(b64ImageArr[2])),
    createMaterialFromTexture(createTextureFromBase64(b64ImageArr[4])),
    createMaterialFromTexture(createTextureFromBase64(b64ImageArr[5])),
    createMaterialFromTexture(createTextureFromBase64(b64ImageArr[0])),
    createMaterialFromTexture(createTextureFromBase64(b64ImageArr[1])),
  ];

  /*
    We use material.side = THREE.DoubleSide -- then we do not need t
    to put negatives on the BoxGeometry instantiation
   */
  //const geometry = new THREE.BoxGeometry( -200, -200, -200 );
  const geometry = new THREE.BoxGeometry( 200, 200, 200 );
  const cube = new THREE.Mesh( geometry, materials );
  //printObject3D(cube, 'cube');
  cube.rotation.order = 'XYZ';

  // We are putting camera inside the cube (with material.side set to
  // THREE.DoubleSide so it shows up inside the cube), so everything will be
  // reversed -- cube.scale.x = -1 fixes that.
  cube.scale.x = -1;
  return cube;
}

function initPictoCubeFromUrl({
  imageUrlArr = [
    'images/out/devil0004.jpg',
    'images/out/devil0003.jpg', 
    'images/out/devil0005.jpg', 
    'images/out/devil0006.jpg', 
    'images/out/devil0001.jpg', 
    'images/out/devil0002.jpg', 
  ]} = {}
) {
  // Create shape.  Ordering from Interactive Studio is: 3,2,4,5,0,1
  const loader = new THREE.TextureLoader();
  const materials = [
    new THREE.MeshBasicMaterial({ map: loader.load(imageUrlArr[4]) }),
    new THREE.MeshBasicMaterial({ map: loader.load(imageUrlArr[1]) }), 
    new THREE.MeshBasicMaterial({ map: loader.load(imageUrlArr[0]) }),
    new THREE.MeshBasicMaterial({ map: loader.load(imageUrlArr[3]) }),
    new THREE.MeshBasicMaterial({ map: loader.load(imageUrlArr[5]) }),
    new THREE.MeshBasicMaterial({ map: loader.load(imageUrlArr[2]) }),
  ];
  const geometry = new THREE.BoxGeometry( -200, -200, -200 );
  const cube = new THREE.Mesh( geometry, materials );
  //printObject3D(cube, 'cube');
  cube.rotation.order = 'XYZ';
  return cube;
}

const drawScene = async (ipixJson) => {
  const scene = initScene();
  const camera = initCamera(0.1);
  const renderer = initRenderer();
  const cube = initPictoCubeFromBase64Arr(ipixJson.pictoArray[0].imageArray);
  scene.add(cube);
  initControls(scene, camera, renderer, cube);
}

const drawWebsite = async (ipixJson) => {
  var divElement = document.createElement('h1');
  var divText = document.createTextNode('Work in progress 3/3/2022! bloopy' + ipixJson);
  divElement.appendChild(divText);
  document.querySelector('body').appendChild(divElement);
}

const main = async () => {
  //console.log(window.location.pathname);
  let path = window.location.pathname;
  let url = 'https://bw5o4rwxkl.execute-api.us-west-2.amazonaws.com/getIpixJson' + path;
  const ipixJson = await getIpixJson(url);
  //console.log('ipixJson:', ipixJson);
  if (ipixJson === 'WTF') {
    await drawWebsite(ipixJson);
  } else {
    await drawScene(ipixJson);
  }
}

/*
const main = async () => {
  console.log(window.location.pathname);
  let path = window.location.pathname;
  let url = 'https://bw5o4rwxkl.execute-api.us-west-2.amazonaws.com/getIpixJson' + path;
  const ipixJson = await getIpixJson(url);
  //console.log(`GETZ: Here's the ipixJson`, ipixJson);
  const scene = initScene();
  const camera = initCamera(0.1);
  const renderer = initRenderer();
  const cube = initPictoCubeFromBase64Arr(ipixJson.pictoArray[0].imageArray);
  scene.add(cube);
  initControls(scene, camera, renderer, cube);
};
*/

main();
